<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>交易查询</el-breadcrumb-item>
                    <el-breadcrumb-item>账户查询</el-breadcrumb-item>
                    <el-breadcrumb-item>备付金查询</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form ref="form" :model="form" label-width="100px">
            <div class="admin_main_block admin_m15">
                <el-col :span="24">
                    <span class="grid-divider">账户信息</span>
                    <el-divider></el-divider>
                </el-col>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item label="金额">
                            <el-input placeholder="" v-model="form.referrerId" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item label="金额">
                            <el-input placeholder="" v-model="form.name" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="admin_main_block admin_m15">
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item label="">
                            <el-button icon="el-icon-refresh" type="primary" @click="getUserinfo">刷新</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                disabled: false,
                span: 5,
                phone:'',
                form: {

                },
            };
        },
        methods: {
            getUserinfo() {
                if (this.$isEmpty(this.phone)) {
                    return false;
                }
                this.$get(this.$api.findSuperiorByPhone + this.phone, {}).then(
                    (res) => {
                        if (res.code === 1) {
                            this.form = res.data;
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                            });
                        }
                    }
                );
            },
        },
        created() {
        },
    };
</script>
<style lang="scss" scoped>
    .el-card {
        text-align: right;
        margin-top: 30px;
    }

    .hight {
        /*height: 60px;*/
    }

    .el-select {
        width: 100%;
    }

    .el-input {
        width: 100%;
    }

    .el-divider--horizontal {
        margin: 12px 0 12px 0;
    }

    .price {
        width: 200px;
    }

    .el-input-number--small {
        width: 200px;
    }
</style>
